import React from "react";
import ReactDOM from "react-dom";

import { UALProvider, withUAL } from "ual-reactjs-renderer";
import {JsonRpc} from 'eosjs';
import {Wax} from '@eosdacio/ual-wax';
import {Anchor} from 'ual-anchor';

import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "./contexts/AppContext";
import "./index.css";

import App from "./App";

const chain = {
  chainId: process.env.REACT_APP_CHAIN_ID,
  rpcEndpoints: [
    {
      protocol: process.env.REACT_APP_RPC_PROTOCOL,
      host: process.env.REACT_APP_RPC_HOST,
      port: process.env.REACT_APP_RPC_PORT
    }
  ]
}


const endpoint = `${process.env.REACT_APP_RPC_PROTOCOL}://${process.env.REACT_APP_RPC_HOST}:${process.env.REACT_APP_RPC_PORT}`;
const rpc = new JsonRpc(endpoint);

const wax = new Wax([chain], {appName: "Forge Arena Portal"});
const anchor = new Anchor([chain], {appName: "Forge Arena Portal"});

const AppConsumer = withUAL(App);

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <BrowserRouter>
        <UALProvider chains={[chain]} authenticators={[wax, anchor]} appName={"Forge Arena Portal"}>
          <AppConsumer rpc={rpc} />
        </UALProvider>
      </BrowserRouter>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
