import { useState } from "react";
import { useAppState } from "./../contexts/AppContext";
import { useNavigate } from "react-router-dom";
import { tryLogin, db } from "./../firebase/firebase";
import { doc, getDoc } from "firebase/firestore";
import authErrors from "./../firebase/authErrors";
import "../styles/login.scss";

export default function Login() {
  //Global context
  const { dispatch } = useAppState();

  //lib hooks
  const navigate = useNavigate();

  //Route specific context
  const [error, updateError] = useState();
  const [email, updateEmail] = useState("");
  const [password, updatePassword] = useState("");

  /**
   * Remix style form action handle
   */
  const actionLogin = async () => {
    try {
      const userCredential = await tryLogin(email, password);

      //Let us fetch and cache the current state of the user doc so it can be used by you in the dashboard
      const docRef = doc(db, "Users", userCredential.user.uid);

      const docSnap = await getDoc(docRef);

      dispatch((draft) => {
        draft.loggedIn = true;
        draft.user = userCredential.user;
        draft.userDoc = docSnap.data();
        draft.walletLinked = draft.userDoc.walletLinked ?? false;
        draft.walletID = draft.userDoc.walletID ?? null;
        draft.genZeroHolder = draft.userDoc.genZeroHolder ?? false;
      });

      navigate("/dashboard");
    } catch (e) {
      if (e.code) {
        updateError(authErrors(e.code.toString()));
      }
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h1>Login</h1>
        <div className="login-container-form">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            value={email}
            id="email"
            className="form-input"
            style={{ display: "block" }}
            placeholder=""
            onChange={(e) => updateEmail(e.target.value)}
          />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            value={password}
            id="password"
            className="form-input"
            style={{ display: "block" }}
            placeholder=""
            onChange={(e) => updatePassword(e.target.value)}
          />
        </div>
        <p className="notice-error">{error}</p>
        <button onClick={actionLogin}>Login</button>
      </div>
      <div className="login-alt-actions">
        <p className="recover" onClick={() => navigate("/reset")}>
          Forgot Password
        </p>
        <p className="register" onClick={() => navigate("/register")}>
          Create Account
        </p>
      </div>
    </div>
  );
}
