export const icons = [
	{ 
		name: "War Pig",
		url: "https://firebasestorage.googleapis.com/v0/b/the-forge-global.appspot.com/o/Icons%2FUserIcon%2FWarPig.jpg?alt=media&token=5b1718af-ea88-4226-b61d-8a493caae99e"
	},
	{
		name: "Janine",
		url: "https://firebasestorage.googleapis.com/v0/b/the-forge-global.appspot.com/o/Icons%2FUserIcon%2FJanine.jpg?alt=media&token=f15a4f29-888a-4f88-a80d-931084f98a06"
	},
	{
		name: "Skull",
		url: "https://firebasestorage.googleapis.com/v0/b/the-forge-global.appspot.com/o/Icons%2FUserIcon%2FSkull.jpg?alt=media&token=d82e6b06-9bd8-4d6b-a7ee-9f59c25a0306"
	},
	{
		name: "Champi",
		url: "https://firebasestorage.googleapis.com/v0/b/the-forge-global.appspot.com/o/Icons%2FUserIcon%2FChampi.jpg?alt=media&token=77e5aa0a-3df5-4418-b4b0-3b2dbdcbc5d3"
	},
	{
		name: "Panda",
		url: "https://firebasestorage.googleapis.com/v0/b/the-forge-global.appspot.com/o/Icons%2FUserIcon%2FPanda.jpg?alt=media&token=02f1a337-e077-4e11-a0eb-5c345f32a9b3"
	},
	{
		name: "Digital Paw",
		url: "https://firebasestorage.googleapis.com/v0/b/the-forge-global.appspot.com/o/Icons%2FUserIcon%2FDiogitalPaw.jpg?alt=media&token=9c78424a-b10d-46c8-86a8-d1e73f0983c1"
	},
];

export const bp01_icons = [
	{
		name: "Chaperon",
		url: "https://firebasestorage.googleapis.com/v0/b/the-forge-global.appspot.com/o/Icons%2FUserIcon%2FBattle%20Pass%2000%2FChaperon.jpg?alt=media&token=bfa4757f-0861-4ba1-8204-d9155949990e"
	},
	{
		name: "Pharaoh",
		url: "https://firebasestorage.googleapis.com/v0/b/the-forge-global.appspot.com/o/Icons%2FUserIcon%2FBattle%20Pass%2000%2FPharaon.jpg?alt=media&token=83f77296-0edd-4088-b950-aa53546a65a8"
	},
	{
		name: "Poulet",
		url: "https://firebasestorage.googleapis.com/v0/b/the-forge-global.appspot.com/o/Icons%2FUserIcon%2FBattle%20Pass%2000%2FPoulet.jpg?alt=media&token=973e9142-550b-4548-9863-0b7ef807a858"
	},
	{
		name: "Pyramid",
		url: "https://firebasestorage.googleapis.com/v0/b/the-forge-global.appspot.com/o/Icons%2FUserIcon%2FBattle%20Pass%2000%2FPyramide.jpg?alt=media&token=f566c910-1b9a-4622-afc2-785e1b97e109"
	}
]

export default function randomIconGen() {
	let max = icons.length;
	let rand = Math.floor(Math.random() * max);
	return icons[rand].url;
}
