import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../contexts/AppContext";
import { UALContext } from "ual-reactjs-renderer";
import { db } from "./../firebase/firebase";

import loading from "./../images/loading.gif";
import { doc, getDoc } from "firebase/firestore";
import generatorCheck from "../util/genValidator";

import "../styles/manageprofile.scss";

const ManageProfile = () => {
  const { state, dispatch } = useAppState();
  const [error, updateError] = useState("");
  const [reqLoading, updateReqLoading] = useState(false);
  const navigate = useNavigate();

  const [curUser, updateCurUser] = useState({
    loaded: false,
    wallet: null,
  });

  const [showSupport, setShowSupport] = useState(false);

  const ual = useContext(UALContext);

  const openExternalUrl = (url) => {
    window.open(url, "_blank");
  };

  // Easier to work with useEffects :)
  const useDidMount = (mountAction) => useEffect(mountAction, [mountAction]);

  useDidMount(() => {
    localStorage.removeItem("UALLoggedInAuthType");
    localStorage.removeItem("ual-wax:autologin");
    if (state.walletID !== null) {
      generatorCheck(state, dispatch);
    }
  });

  useEffect(() => {
    (async () => {
      const docRef = doc(db, "Users", state.user.uid);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      updateCurUser({
        loaded: true,
        walletLinked: data.walletLinked,
        walletID: data.walletID,
      });
    })();
  }, [state.user.uid]);

  useEffect(() => {
    triggerLink();
  }, [ual.activeUser]);

  const triggerLink = async () => {
    if (ual.activeUser && !curUser.walletLinked) {

      if (ual.activeUser.isTemp) {
        setShowSupport(true);
        updateReqLoading(false);
        return;
      }

      let walletId = ual.activeUser.accountName;
      const uuid = state.user.uid;
      //Check uniqueness of the linked wallet

      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          uuid,
          wallet: walletId,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        let lres = await fetch(
          "https://us-central1-the-forge-global.cloudfunctions.net/linkwalletv2",
          requestOptions
        );

        let ldata = await lres.json();

        if (ldata.error) {
          console.log(ldata.error);
          updateReqLoading(false);
          return updateError(ldata.msg);
        } else {
          dispatch((draft) => {
            draft.walletLinked = ldata.linked;
            draft.walletID = walletId;
            draft.genZeroHolder = ldata.genZeroHolder;
          });

          setTimeout(() => {
            navigate("/dashboard");
          }, 1500);
        }
      } catch (e) {
        console.log(e);
        updateError("A network error occured. Please contact support!");
      }
    }
  };

  const linkWallet = async () => {

    // Display the WAX LOGIN modal.
    updateReqLoading(true);
    ual.showModal();
    //Handle wallet linking logic.
  };

  const unlinkWallet = () => {
    //Handle wallet unlinking logic.

    updateError(
      "Unlinking is disabled in beta. Contact support for assistance."
    );
  };

  if (curUser.loaded) {
    return (
      <>
        <div className="manage-profile">
          <h2>Manage Profile</h2>

          <div className="profile-field">
            <p className="profile-field-key">Wax Wallet: </p>

            <p className="profile-field-value">
              {curUser.walletID ? curUser.walletID : "No Wallet Linked"}
            </p>

            <button
              onClick={() => {
                curUser.walletLinked ? unlinkWallet() : linkWallet();
              }}
            >
              {reqLoading ? (
                <img height={20} width={20} src={loading} alt="spinner" />
              ) : curUser.walletLinked ? (
                "Unlink Wallet"
              ) : (
                "Link Wallet"
              )}
            </button>
          </div>
          <p className="notice-error">{error}</p>
        </div>
        {showSupport && (
            <div className="support-widget">
              <h2>ACTIVATION ERROR</h2>
              <p>Unable to link wallets that have not been activated yet. Please submit a ticket in our community discord.</p>
              <div className="support-btn" onClick={() => {
                openExternalUrl('https://discord.gg/playforge')
              }}>
                Get Support in Discord
              </div>
            </div>
          )}
      </>
    );
  } else {
    return (
      <div className="manage-profile">
        <h2>Manage Profile</h2>
        <img height={30} width={30} src={loading} alt="spinner" />
        <br />
      </div>
    );
  }
};

export default ManageProfile;