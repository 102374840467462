import { useState } from "react";
import { useAppState } from "./../contexts/AppContext";
import { useNavigate } from "react-router-dom";
import { createAccount, db } from "./../firebase/firebase";
import { doc, setDoc } from "firebase/firestore";
import randomIconGen from "./../util/randomIcon";

import '../styles/register.scss';

export default function Register() {
  //Global context
  const { dispatch } = useAppState();

  //lib hooks
  const navigate = useNavigate();

  //Route specific context
  const [email, updateEmail] = useState("");
  const [username, updateUsername] = useState("");
  const [password, updatePassword] = useState("");
  const [confirmPassword, updateConfirmPassword] = useState("");
  const [firstName, updateFirstName] = useState("");
  const [lastName, updateLastName] = useState("");

  const [error, updateError] = useState("");

  /**
   * Remix style form action handle
   */

  const actionSignUp = async () => {
    if (password !== confirmPassword) {
      return updateError("Both passwords do not match");
    }

    try {
      const userCredential = await createAccount(email, password);

      //Directly from them should be improved later as collisons may occur
      const discriminator = Math.floor(9999 * Math.random() + 1e3);
      //Add link to an icon here
      const icon = randomIconGen();

      const userStructure = {
        email,
        fname: firstName,
        lname: lastName,
        username: username + "#" + discriminator,
        usernameSearch: username.toLowerCase() + "#" + discriminator,
        usernameNumber: discriminator,
        friendlist: [],
        friendRequest: [],
        blockedList: [],
        region: doc(db, "Games", "theForgeArena", "Regions", "NA"),
        icon,
      };

      await setDoc(doc(db, "Users", userCredential.user.uid), userStructure);

      dispatch((draft) => {
        draft.loggedIn = true;
        draft.user = userCredential.user;
        draft.userDoc = userStructure;
      });
      navigate("/dashboard");
    } catch (e) {

      if (e.code) {
        updateError(e.code);
      }
    }
  };

  return (
    <div className="register-container">
      <h1>Register an Account</h1>
      <div className="register-container-form">

        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          className="form-input"
          value={email}
          onChange={(e) => updateEmail(e.target.value)}
        />
        <label htmlFor="username">Username</label>
        <input
          type="text"
          id="username"
          className="form-input"
          value={username}
          onChange={(e) => updateUsername(e.target.value)}
        />
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          className="form-input"
          value={password}
          onChange={(e) => updatePassword(e.target.value)}
        />
        <label htmlFor="confirmpassword">Confirm Password</label>
        <input
          type="password"
          id="confirmpassword"
          className="form-input"
          value={confirmPassword}
          onChange={(e) => updateConfirmPassword(e.target.value)}
        />
        <label htmlFor="firstName">First Name</label>
        <input
          type="text"
          id="firstName"
          className="form-input"
          value={firstName}
          onChange={(e) => updateFirstName(e.target.value)}
        />
        <label htmlFor="lastName">Last Name</label>
        <input
          type="text"
          id="lastName"
          className="form-input"
          value={lastName}
          onChange={(e) => updateLastName(e.target.value)}
        />
        <button onClick={actionSignUp}>Register Account</button>
        <p className="notice-error">{error}</p>
      </div>
    </div>
  );
}
