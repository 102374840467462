import { useState } from "react";

import { confirmRecovery } from "./../firebase/firebase";

import { useSearchParams } from "react-router-dom";

import '../styles/confirmreset.scss';

export default function ConfirmReset() {
  const [password, updatePassword] = useState("");
  const [error, updateError] = useState("");
  const [changed, updateChanged] = useState(false);

  const [searchParams] = useSearchParams();

  const changePassword = async () => {
    const oobCode = searchParams.get("oobCode");

    if (oobCode !== undefined) {
      try {
        await confirmRecovery(oobCode, password);
        updateChanged(true);
      } catch (e) {
        if (e.code) {
          updateError("An error occurred.");
        }
      }
    } else {
      updateError("Please recheck the email for a valid recovery link.");
    }
  };

  return (
    <div className="confirm-reset-container">
      <h1>Confirm Reset</h1>

      <div className="confirm-reset-container-form">
        <label htmlFor="password">Enter new password</label>
        <input
          type="password"
          className="form-input"
          id="password"
          style={{ display: "block" }}
          value={password}
          onChange={(e) => updatePassword(e.target.value)}
        />
        <button onClick={changePassword}>Reset Password</button>

        {changed ? <p className="notice-success">Succesfully updated password.</p> : <p className="notice-error">{error}</p>}
      </div>
      
    </div>
  );
}
