import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";
import Login from "./routes/Login";
import { useAppState } from "./contexts/AppContext";
import Dashboard from "./routes/Dashboard";
import Register from "./routes/Register";
import RecoverPassword from "./routes/RecoverPassword";
import ConfirmReset from "./routes/ConfirmReset";
import Topbar from "./components/topbar/topbar.jsx";
import ManageProfile from "./routes/ManageProfile";
import forgeArenaLogo from "./images/stacked_logo.png";

import "./styles/app.scss";

function App() {
  const { state } = useAppState();

  return (
    <div className="app">
      <Topbar />
      <img
        className="forgeArenaLogo"
        src={forgeArenaLogo}
        alt="forge arena logo"
      />
      <Routes>
        {/* If already logged in provide dashboard otherwise provide login. */}
        <Route path="/" element={state.loggedIn ? <Dashboard /> : <Login />} />

        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ManageProfile />
            </PrivateRoute>
          }
        />
        <Route path="/reset" element={<RecoverPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/confirm-reset" element={<ConfirmReset />} />
      </Routes>
    </div>
  );
}

export default App;
