import React, { createContext, useContext, useReducer } from "react";
import produce from "immer";

export const AppContext = createContext({});

const { Provider } = AppContext;

export const AppProvider = ({ children }) => {
  //Default application state

  const defaultAppState = {
    loggedIn: false,
    walletLinked: false,
    walletID: null,
    genZeroHolder: false,
    user: {},
    userDoc: {},
  };



  const [state, dispatch] = useReducer((state, action) => {
    const newState = produce(state, action);
    localStorage.setItem('app', JSON.stringify(newState));
    return newState;
  },  localStorage.getItem('app') ? JSON.parse(localStorage.getItem('app')) : defaultAppState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export const useAppState = () => useContext(AppContext);
