import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppState } from "./../contexts/AppContext";
// import { db } from "./../firebase/firebase";
// import { doc, updateDoc} from 'firebase/firestore';
import generatorCheck from "../util/genValidator";

import "../styles/dashboard.scss";

export default function Dashboard() {
  const { state, dispatch } = useAppState();

  // Easier to work with useEffects :)
  const useDidMount = (mountAction) => useEffect(mountAction, [mountAction]);

  useDidMount(() => {
    if (state.walletID !== null) {
      generatorCheck(state, dispatch);
    }
  });

  return (
    <div className="dashboard">
      <h2>
        HOW TO GET INTO <span>THE ARENA</span>
      </h2>
      <div className="action-step">
        <span>01</span>
        <h3>In your Profile link a WAX Wallet</h3>
        {state.walletLinked ? (
          <span className="check-mark">✅</span>
        ) : (
          <span></span>
        )}
        <span className="check-mark"
          style={{
            cursor: "pointer",
          }}><Link to='/profile' style={{textDecoration: 'none', cursor: 'pointer'}}>🔗</Link></span>
      </div>

      <div className="action-step">
        <span>02</span>
        <h3>See you in the arena</h3>
        {state.walletLinked ? (
          <span className="check-mark">✅</span>
        ) : (
          <span></span>
        )}
      </div>

      {state.walletLinked && (
        <a
          href="https://playforge.gg/play"
          rel="noreferrer"
        >
          <button>Play Now</button>
        </a>
      )}
    </div>
  );
}
