import { useAppState } from "./../contexts/AppContext";
import { Navigate } from "react-router-dom";

export default function PrivateRoute({ children }) {
  const { state} = useAppState();

  if (state.loggedIn) {
    return <div>{children}</div>;
  }

  return <Navigate to="/" />;
}