import { useAppState } from '../../contexts/AppContext';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../images/logo.png';
import './topbar.scss';

const Topbar = () => {

    const { state, dispatch } = useAppState();
    const navigate = useNavigate();
    const location = useLocation();

    const logOut = () => {
        dispatch((draft) => {
            draft.loggedIn = false;
            draft.user = null;
            draft.userDoc = null;
            draft.walletLinked = false;
            draft.walletID =  null;
            draft.genZeroHolder = false;
        })
        navigate('/');
    }


    return (
        <nav>
            <div className="brand">
                <img onClick={() => navigate('/dashboard')} src={logo} alt="forge arena logo" />
                <div className="brand-info">
                    <h1>FORGE ARENA PORTAL</h1>
                    <p>Powered by <a href="https://www.playforge.gg/" rel="noreferrer" target="_blank"><span>NEWGEN LABS</span></a></p>
                </div>

            </div>

            {state.loggedIn &&
                <div className="right-topbar">
                    <div className="navigation">
                        <p onClick={() =>navigate('/dashboard')}className={`tab ${location.pathname === "/dashboard" ? "selected" : ""}`}>Dashboard</p>
                        <p onClick={() =>navigate('/profile')}className={`tab ${location.pathname === "/profile" ? "selected" : ""}`}>Profile</p>
                    </div>
                    <div className="profile">
                        <img src={state.userDoc.icon} alt="user icon" />
                        <div className="quick-actions">
                            <h2>{state.userDoc.username}</h2>
                            {state.loggedIn && <button onClick={() => logOut()}>Logout</button>}
                        </div>

                    </div>
                </div>
            }
        </nav>
    )
}

export default Topbar;
