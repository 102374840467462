import { useState } from "react";

import { requestRecovery} from "./../firebase/firebase";
import '../styles/recoveraccount.scss';


export default function RecoverPassword() {
  const [email, updateEmail] = useState("");
  const [error, updateError] = useState("");
  const [sentEmail, updateSentEmail] = useState(false);

  const sendRecoveryEmail = async () => {
    try {
      await requestRecovery(email);
      updateSentEmail(true);
    } catch (e) {
      if (e.code) {
        updateError(e.code);
      }
    }
  };

  return (
    <div className="reset-container">
      <h1>Reset Password</h1>

      <div className="reset-container-form">
        <label>Enter account email</label>
        <input
          type="email"
          style={{ display: "block" }}
          value={email}
          className="form-input"
          id="email"
          onChange={(e) => updateEmail(e.target.value)}
          placeholder="Enter email"
        />
        <button onClick={sendRecoveryEmail}>Reset Password</button>
        
        {sentEmail ? <p className="notice-success">A reset request has been sent to your email.</p> : <p className="notice-error">{error}</p>}
      </div>
    </div>
  );
}

