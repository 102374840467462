import { db } from "./../firebase/firebase";
import { doc, updateDoc } from "firebase/firestore";

export default async function generatorCheck(state, dispatch) {
  let genZeroHolderPrevState = state.genZeroHolder;

  try {
    const ascCapReq = `https://wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=forge.gene&owner=${state.walletID}&page=1&limit=1000&order=desc&sort=asset_id`;
    const genZeroReq = `https://wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=generatorzer&schema_name=nftgenerator&owner=${state.walletID}&page=1&limit=1000&order=desc&sort=asset_id`;
    const res = await Promise.all([fetch(ascCapReq), fetch(genZeroReq)]);
    const data = await Promise.all([res[0].json(), res[1].json()]);

    let genZeroCheck = false;

    if (data[0].data.length >= 1 || data[1].data.length >= 1) {
      genZeroCheck = true;
    } else {
      genZeroCheck = false;
    }

    if (genZeroHolderPrevState !== genZeroCheck) {
      dispatch((draft) => {
        draft.genZeroHolder = genZeroCheck;
      });

      //Update firebase here
      await updateDoc(doc(db, "Users", state.user.uid), {
        genZeroHolder: genZeroCheck,
      });
    } else {
    }
  } catch (e) {}
}
